/*----------------------------------------*/
/*  41 - Pages - Login | Register
/*----------------------------------------*/
.login-register_area {
	padding: 100px 0;
}
[class*="col-"] {
	&:first-child {
		form {
			.login-form {
				@include breakpoint (max-md_device) {
					margin-bottom: 30px;
				}
			}
		}
	}
}
.login-form {
	background-color: $white-color;
	padding: 30px;
	-webkit-box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1);
	box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.1);
	.login-title {
		font-size: 20px;
		line-height: 23px;
		text-transform: capitalize;
		font-weight: 700;
		margin-bottom: 0;
		padding-bottom: 20px;
	}
	label {
		display: block;
		font-size: 14px;
		margin-bottom: 12px;
		font-weight: 500;
		text-transform: capitalize;
		@include breakpoint(lg-device){
			font-size: 12px;
		}
	}
	input {
		width: 100%;
		background-color: transparent;
		border: 1px solid $border-color;
		border-radius: 0;
		line-height: 23px;
		padding: 10px 20px;
		font-size: 14px;
		margin-bottom: 15px;
	}
	input[type="checkbox"] {
		width: auto;
	}
}

.login-form {
	.check-box {
		float: left;
		margin-right: 70px;
		&:last-child {
			margin-right: 0;
		}
	}
}
.forgotton-password_info {
	@include breakpoint(max-sm_device) {
		padding-top: 15px;
	}
	a{
		font-size: 14px;
		@include breakpoint(lg-device){
			font-size: 12px;
		}
	}
}

/*-- Place Order --*/
.login_btn,
.register_btn {
	background-color: $text-color;
	color: $white-color;
	display: block;
	margin-top: 30px;
	width: 140px;
	border-radius: 0;
	height: 40px;
	line-height: 40px;
	border: 0;
	text-transform: uppercase;
	@extend %basetransition;
	@include breakpoint (max-sm_device) {
		margin-top: 25px;
	}
	&:hover {
		background: $primary-color;
	}
}
.register_btn {
	margin-top: 15px;
}
