/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/
/* ---Background Color--- */
.bg-smoke_color{
    background-color: $white-smoke_color;
}
.bg-white_color{
    background-color: $white-color;
}
.bg-wisp-pink{
    background-color: $wisp-pink_color;
}
.bg-snow{
    background-color: $snow-color;
}
.bg-clear_day{
    background-color: #ddeee9;
}
.bg-black{
    background-color: #000;
}
.bg-night_rider{
    background-color: #313131;
}
.bg-fantasy{
    background-color: #f0e5dd;
}
.bg-dim_gray{
    background-color: #6E6E6E;
}
.bg-snow{
    background-color: #f9f9f9;
}
.bg-cavern_pink{
    background-color: #dCb6b6;
}
.bg-hint-of_red{
    background-color: #f6f4f2;
}
.bg-tranquil{
    background-color: #e0f4f0;
}
.bg-bokara_grey{
    background-color: $text-color;
}
.bg-sorbus{
    background-color: $primary-color;
}