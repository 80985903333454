/*----------------------------------------*/
/*  39 - Pages - Error 404
/*----------------------------------------*/
.error-404_area{
    padding: 45px 0 150px;
    @include breakpoint(max-lg_device){
        padding: 55px 0 150px;
    }
    .error-404_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        > h1{
            color: $primary-color;
            line-height: 150px;
            font-size: 150px;
            padding-bottom: 35px;
        }
        > h2{
            &.sub-title{
                color: $charcoal-color;
                padding-bottom: 20px;
                line-height: 55.2px;
                font-size: 46px;
                @include breakpoint(max-sm_device){
                    font-size: 25px;
                    line-height: 32px;
                }
            }
        }
        > p{
            font-family: 'Roboto', sans-serif;
            color: $charcoal-color;
        }
        .error-btn_wrap{
            padding-top: 35px;
            > a{
                &.yena-btn{
                    color: $charcoal-color;
                    text-transform: none;
                    font-size: 14px;
                }
            }
        }
    }
}