/*----------------------------------------*/
/*  14 - Components - Product
/*----------------------------------------*/
.product-item {
    .single-product {
        @extend %basetransition;
        .product-img {
            position: relative;
            text-align: center;
            overflow: hidden;
            border: 1px solid $border-color;
            > a {
                display: block;
                img{
                    width: 100%;
                    height: 100%;
                    &.secondary-img {
						position: absolute;
						left: 0;
						top: 0;
						opacity: 0;
						width: 100%;
						@extend %basetransition;
					}
                }
            }
            .add-actions {
                position: absolute;
                bottom: -50px;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                z-index: 9;
                > ul {
                    > li {
                        display: inline-block;
                        transform: translateY(0);
                        &:not(:last-child){
                            margin-right: 5px;
                        }
                        @extend %basetransition;
                        &:first-child {
                            transition-delay: 100ms;
                        }
                        &:nth-child(2) {
                            transition-delay: 150ms;
                        }
                        &:nth-child(3) {
                            transition-delay: 200ms;
                        }
                        &:last-child {
                            transition-delay: 250ms;
                            > a{
                                padding-right: 0;
                            }
                        }
                        > a {
                            background-color: $white-color;
                            border: 1px solid $border-color;
                            width: 40px;
                            height: 40px;
                            line-height: 42px;
                            border-radius: 100%;
                            display: block;
                            text-align: center;
                            &:hover{
                                background-color: $text-color;
                                border-color: $text-color;
                                color: $white-color !important;
                            }
                            > i {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            &:hover {
                .secondary-img {
                    opacity: 1;
                }
            }
        }
        .product-content {
            padding-top: 15px;
            .product-desc_info {
                .price-box {
                    @include new-price;
                    @include old-price;
                }
                .product-category{
                    position: relative;
                    margin-bottom: 10px;
                    padding-left: 30px;
                    a {
                        font-size: 14px;
                        display: block;
                        &:before{
                            content: "";
                            background-color: $silver-color;
                            height: 2px;
                            width: 20px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                h3{
                    &.product-name{
                        font-family: 'Roboto', sans-serif;
                        font-weight: 600;
                        margin-bottom: 10px;
                        font-size: inherit;
                        > a{
                            font-size: 16px;
                            line-height: 19.2px;
                            @include breakpoint(max-xl_device){
                                font-size: 14px;
                            }
                        }
                    }
                }
                .price-box{
                    > span{
                        font-family: 'Roboto', sans-serif;
                        &.new-price{
                            color: $charcoal-color;
                            line-height: 20.8px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        &:hover {
            .product-img {
                .add-actions {
                    > ul{
                        > li{
                            transform: translateY(-80px);
                        }
                    }
                }
            }
        }
    }
}

/* ---Yena's Product Area--- */
.yena-product_area{
    padding: 90px 0 0;
}

/* ---Yena's Product Area Three--- */
.yena-product_area-3{
    padding: 95px 0;
}

/* ---Product Area Five--- */
.yena-product_area-5{
    padding-top: 95px;
}