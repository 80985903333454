/*----------------------------------------*/
/*  23 - Components - Sidebar
/*----------------------------------------*/
/* ---Sidebar Area--- */
.sidebar-area,
.filter-body{
    .search-box{
        position: relative;
        input{
            &.search-box_input{
                border: 1px solid $border-color;
                width: 100%;
                height: 60px;
                padding: 0 45px 0 15px;
            }
        }
        button{
            &.search-box_btn{
                color: $charcoal-color;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }
    .widgets-area{
        padding-top: 55px;
        > h2{
            &.heading{
                font-size: 22px;
                line-height: 26.4px;
                > span{
                    position: relative;
                    padding-bottom: 15px;
                    margin-bottom: 30px;
                    display: inline-block;
                    &:before{
                        background-color: $text-color;
                        width: 50%;
                        height: 1px;
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
        .widgets-item{
            > ul{
                > li{
                    > a{
                        font-family: 'Playfair Display', serif;
                        font-style: italic;
                        padding: 10px 0;
                        border-bottom: 1px solid $border-color;
                        display: block;
                    }
                    &:first-child{
                        > a{
                            padding-top: 0;
                        }
                    }
                }
            }
        }
        .instagram-area{
            .instagram-item{
                &.instagram-col{
                    display: flex;
                    max-width: 33.333%;
                }
            }
        }
        .widgets-tags{
            > ul{
                > li{
                    display: inline-block;
                    > a{
                        font-size: 14px;
                    }
                }
            }
            &.with-border{
                margin-bottom: -5px;
                > ul{
                    > li{
                        margin-bottom: 10px;
                        &:not(:last-child){
                            margin-right: 5px;
                        }
                        > a{
                            border: 1px solid $border-color;
                            display: block;
                            padding: 10px;
                        }
                    }
                }
            }
        }
        .widgets-banner{
            position: relative;
            cursor: pointer;
            .widgets-banner_text{
                position: absolute;
                @include absCenter;
                text-align: center;
                pointer-events: none;
                z-index: 1;
                span{
                    font-family: 'Playfair Display', serif;
                    color: $white-color;
                    font-size: 32px;
                    line-height: 32px;
                    font-style: italic;
                    > strong{
                        display: block;
                        font-size: 100px;
                        line-height: 100px;
                    }
                }
            }
        }
        &.archives-area{
            @include breakpoint(max-md_device){
                padding-bottom: 30px;
            }
            .myniceselect{
                &.wide{
                    span{
                        padding: 0 60px 0 15px;
                    }
                }
            }
        }
        .list-product_area{
            .single-blog{
                display: flex;
                .blog-img{
                    width: 80px;
                }
                .blog-content{
                    width: calc(100% - 80px);
                    padding-left: 15px;
                    > h3{
                        &.title{
                            font-family: 'Playfair Display', serif;
                            font-size: 16px;
                            line-height: 22.4px;
                            color: $charcoal-color;
                            font-weight: 300;
                        }
                    }
                    .post-meta{
                        > ul{
                            > li{
                                color: $nobel-color;
                                font-size: 10px;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
            [class*="single-blog"]{
                &:not(:last-child){
                    border-bottom: 1px solid $border-color;
                    margin-bottom: 25px;
                    padding-bottom: 25px;
                }
            }
        }
        .widgets-checkbox{
            > li{
                &:not(:last-child){
                    padding-bottom: 15px;
                }
                input[type="checkbox"] + label {
                    font-family: 'Playfair Display', serif;
                    position: relative;
                    padding-left: 25px;
                    line-height: 15px;
                    font-size: 13px;
                    font-weight: 400;
                    text-transform: uppercase;
                    color: $charcoal-color;
                    &:before {
                        width: 15px;
                        height: 15px;
                        display: block;
                        border: 1px solid $border-color;
                    }
                    &:after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        content: "\ea20";
                        font-family: "LaStudioIcons";
                        font-weight: 300;
                        font-size: 10px;
                        line-height: 15px;
                        opacity: 0;
                        width: 15px;
                        text-align: center;
                        background-color: $primary-color;
                        color: $white-color;
                    }
                }
                input[type="checkbox"]:checked + label {
                    &:before {
                        border: 1px solid $border-color;
                    }
                    &:after {
                        opacity: 1;
                    }
                }
                &:hover{
                    input[type="checkbox"] + label{
                        color: $primary-color;
                        &:before {
                            border: 1px solid $border-color;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}