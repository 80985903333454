/*----------------------------------------*/
/*  38 - Pages - Coming Soon
/*----------------------------------------*/
.coming-soon_area{
    background-image: url('../images/coming-soon/bg/1.jpg');
    background-repeat: no-repeat;
	background-position: center center;
    background-size: cover;
    height: 100vh;
    position: relative;
    @include breakpoint(max-xxs_device){
        background-position: left;
    }
    .coming-soon_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 50%;
        @include breakpoint(max-md_device){
            width: 65%;
        }
        @include breakpoint(max-sm_device){
            width: 100%;
        }
        > h2{
            &.heading{
                position: relative;
                color: $white-color;
                padding-bottom: 30px;
                margin-bottom: 30px;
                &:before{
                    background-color: $white-color;
                    content: "";
                    width: 95px;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        .coming-soon_btn{
            display: flex;
            justify-content: center;
            padding-top: 40px;
            > a{
                &.yena-btn{
                    &:hover{
                        background-color: $primary-color;
                    }
                }
            }
        }
        .social-link{
            padding-top: 60px;
            text-align: center;
            > ul{
                > li{
                    padding-right: 50px;
                    &:last-child{
                        padding-right: 0;
                    }
                    > a{
                        color: $white-color;
                        font-size: 30px;
                    }
                }
            }
        }
    }
}