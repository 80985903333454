//--- All Variables - Related Stuff Included In This Area ---↓
// Color
$primary-color:     #dd6b33;
$text-color:        #211e1c;
$heading-color:     #202020;
$border-color:      #e5e5e5;
$border-color-2:    #ebebeb;
$border-color-3:    #d8d8d8;

// Additional Color Variable
$white-color:       #ffffff;
$white-smoke_color: #f5f5f5;
$silver-color:      #bbbbbb;
$snow-color:        #f9f9f9;
$gamboge-color:     #DC9B0E;
$zircon-color:      #DAE5E7;
$gainsboro-color:   #dedede;
$wisp-pink_color :  #f3ebe9;
$night-rider_color :#313131;
$nobel-color:       #979797;
$charcoal-color:    #494949;
$eclipse-color:     #3e3e3e;

// Slider Color Swatches
$burnt-orange_color:#ff832b;
$raw-umber_color:   #824900;
$black-color:       #000000;
$brown-color:       #a6311f;
$red-color:         #ff0000;

//----Social Link Color Variables ----↓
$facebook-color:    #3b579d;
$twitter-color:     #3acaff;
$google-plus-color: #dd4b39;
$linkedin-color:    #0097bd;
$pinterest-color:   #cb2027;
$instagram-color:   #833ab4;
$skype-color:       #00aff0;
$share-color:       #fe6d4c;
$youtube-color:     #d72525;
$vimeo-color:       #00adef;
